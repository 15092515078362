// Customizable Area Start

import React from "react";

import {Typography, Box, Grid, TextField,styled, FormControl, FormLabel, Button, Dialog, InputAdornment,IconButton, Checkbox} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckIcon from '@mui/icons-material/Check';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const MainBoxTextArea=styled(Box)({
  position: "absolute",
  background: "#FFFFFF33",
  bottom:38,
  borderRadius: "5px",
  backdropFilter: "blur(5px)",
  padding:"1rem",
  textAlign: "center"
})

const RightGrid = styled(Grid)({
  position: "relative",
  justifyContent: "center",
  paddingTop: "74px",
  paddingLeft: "112px",
  paddingRight: "100px",
})

const CustomTextfield = styled(TextField) ({
  '& .MuiOutlinedInput-root':{
    borderRadius: "8px",
    border: "1px", 
    marginTop: "4px",
    color: "#003249",
    fontFamily: "Spartan",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "-0.02em",
  }
})

const CustomDialog=styled(Dialog)({
  "& .MuiDialog-paper":{
    borderRadius: "15px"
  }
})

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  fontFamily: "Spartan",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "22px",
  letterSpacing: "-0.02em",
  textAlign: "left",
  color: "#64748B",
}));

const MainWrapper = styled(Box)(({ theme }) => ({
  "& .react-tel-input .special-label": {
      display:'none'
  },
  "& .react-tel-input .form-control": {
      display: 'none',
      width: 'calc(100% - 46px)',
  },
}));

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      isUppercaseValid,
      isLengthValid,
      isNumberValid,
      isSpecialCharValid,
      isEmailValid,
      isPhnNoValid,
    } = this.state;
    return (
      <>
      <MainWrapper>
        <Grid container>
          <Grid item xs={12} sm={6} 
          sx={
            { position:"relative", display:'flex',justifyContent:'center' }
          }>
            <Box 
            style ={{
              display: "flex",
              width: "100%",
              height: "100%",
            }}>
              <img src = {this.state.bgImage} 
              style={
              { 
                width: "100%",   
                height: "100%",   
                objectFit: "cover", 
                position:'relative'}
              }/>
            </Box>
            <Box 
            sx={{
              backgroundColor: "#FEFEFE26", borderRadius: "14px",
              position: "absolute", boxShadow: "-5px 4px 26px 0px #0000001A",
              width: { xs: "40px", sm: "48px", md: "54px" },
              height: { xs: "40px", sm: "48px", md: "54px" }, 
              top: { xs: "20px", sm: "30px", md: "50px" }, 
              left: { xs: "20px", sm: "30px", md: "50px" }, 
            }}>
              <Box 
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: { xs: "8px", sm: "10px", md: "14px" }, 
              }}
              >
                <img src={this.state.arrowImage}/>
              </Box>
            </Box>
            <MainBoxTextArea>
              <Box>
                <Typography 
                style={{
                  color: "#FFFFFF",
                  fontFamily: "Spartan",
                  fontSize: "30px",
                  fontWeight: 700,
                  lineHeight: "40px",
                  letterSpacing: "-0.03em",
                  paddingTop:"28px",
                  paddingBottom: "10px",
                  paddingLeft: "70px",
                  paddingRight: "70px",
                }}>
                  Find Your Horse's Perfect Stay
                </Typography>
              </Box>  
              <Box>
                <Typography 
                style={{
                  color: "#FFFFFF",
                  fontFamily: "Spartan",
                  fontSize: "20px",
                  fontWeight: 500,
                  lineHeight: "30px",
                  letterSpacing: "-0.02em",
                  textAlign: "center", 
                  paddingBottom: "32px",
                  paddingLeft: "85px",
                  paddingRight: "85px",
                }}>
                  Discover reliable, temporary stabling <br/> options effortlessly.
                </Typography>
              </Box>         
            </MainBoxTextArea>
          </Grid>
          <RightGrid item xs={12} sm={6}>
            <Box>
              <Box 
              style={{
                width: "520px",
                height: "76px",
              }}>
                <Typography 
                style={{
                  fontFamily: "Spartan",
                  fontSize: "30px",
                  fontWeight: 700,
                  lineHeight: "40px",
                  letterSpacing: "-0.03em",
                  textAlign: "left",
                  color: "#003249",
                }}>
                  Create your account
                </Typography>
                <Typography 
                style={{
                  fontFamily: "Spartan",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "-0.02em",
                  textAlign: "left",
                  color: "#475569",
                  marginTop: "12px",
                }}>
                  Already have an account?
                  <span 
                  onClick={this.navigateToLoginPage}
                  data-test-id="navigateToLoginPage"
                  style={{
                    cursor: "pointer",
                    fontFamily: "Spartan",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "24px",
                    letterSpacing: "-0.03em",
                    textAlign: "left",
                    textDecoration: "underline",
                    marginLeft: "8px",
                    color: "#003249",
                  }}>
                    Log in
                  </span>
                </Typography>
              </Box>
              <Box 
              style={{
                width: "520px",
                height: "492px",
                gap: "16px",
                marginTop: "40px",
              }}>
                <Box 
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%"
                }}>
                  <Box style={{display: "flex", flexDirection: "column", justifyContent: "left", width: "256px"}}>
                    <Typography 
                    style={{
                      fontFamily: "Spartan",
                      fontSize: "14px",
                      fontWeight: 700,
                      lineHeight: "22px",
                      letterSpacing: "-0.02em",
                      textAlign: "left",
                      color: "#64748B",
                    }}>
                      First name
                    </Typography>
                    <CustomTextfield
                    onChange={this.handleFirstName}
                    value={this.state.firstName}
                    data-test-id="handleFirstName"
                    placeholder="Enter your first name"
                    type="first name"
                    name="first name"
                    variant="outlined"
                    />
                  </Box>
                  <Box style={{display: "flex", flexDirection: "column", justifyContent: "left", marginLeft: "8px", width: "256px"}}>
                    <Typography
                    style={{
                      fontFamily: "Spartan",
                      fontSize: "14px",
                      fontWeight: 700,
                      lineHeight: "22px",
                      letterSpacing: "-0.02em",
                      textAlign: "left",
                      color: "#64748B",
                    }}>
                      Last name
                    </Typography>
                    <CustomTextfield 
                    onChange={this.handleLastName}
                    value={this.state.lastName}
                    data-test-id="handleLastName"
                    placeholder="Enter your last name"
                    type="last name"
                    name="last name"
                    variant="outlined"
                    /> 
                  </Box>
                </Box>
                <Box 
                style={{
                  width: "520px",
                  height: "82px",
                }}>
                  <Typography 
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "22px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    marginTop: "16px",
                    color: "#64748B",
                  }}>
                    Email address
                  </Typography>
                  <CustomTextfield 
                    onChange={this.handleEmailChange}
                    value={this.state.email}
                    data-test-id="handleEmailChange"
                    error={!!this.state.emailError}
                    placeholder="mail@example.com"
                    type="email"
                    name="email"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CheckIcon
                            style={this.getCheckIconStyle(isEmailValid)}
                          />
                        </InputAdornment>
                      ),
                    }}
                    />
                    {this.state.emailError!="" && 
                      <p style={{
                        color: "#DC2626",
                        fontFamily: "Spartan !important",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        letterSpacing: "-0.02em",
                        textAlign: "left",
                        marginTop: "-2px",
                        }}>
                        {this.state.emailError}
                      </p>
                    }
                </Box>
                <Box style={{width: "520px", height: "82px", marginTop: "16px"}}>
                  <FormControl fullWidth >
                    <StyledFormLabel htmlFor="phoneNumber">
                      Phone number
                    </StyledFormLabel>
                      <PhoneInput
                        inputProps={{
                          id: "phoneNumber",
                          name: "phoneNumber",
                          endAdornment: (
                            <InputAdornment position="end">
                              {isPhnNoValid && <CheckIcon style={{ color: '#10B981' }} />}
                            </InputAdornment>
                          ),
                        }}
                        data-test-id="handlePhoneNumber"
                        country={"us"}
                        value={this.state.phone}
                        onChange={this.handlePhoneNumber}
                        enableSearch={true}
                        disableSearchIcon={false}
                        countryCodeEditable={true}
                        inputStyle={{
                          marginTop: "2px", 
                          width: "100%",
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "8px",
                          color: "#003249",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "24px",
                          fontFamily: "Spartan",
                          letterSpacing: "-0.02em",
                        }}
                      />
                      {this.state.phoneError!="" && 
                      <p style={{
                        color: "#DC2626",
                        fontFamily: "Spartan !important",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        letterSpacing: "-0.02em",
                        textAlign: "left",
                        marginTop: "-2px",
                        }}>
                        {this.state.phoneError}
                      </p>
                    }
                  </FormControl>
                </Box>
                <Box style={{width: "520px", height: "82px", marginTop: "16px"}}>
                  <Typography 
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "22px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: "#64748B",
                     marginTop: "20px"
                  }}>
                    Password
                  </Typography>
                  <CustomTextfield 
                  onChange={this.handleNewPassword}
                  value={this.state.newPassword}
                  data-test-id="handleNewPassword"
                  error={!!this.state.newPasswordError}
                  placeholder="Enter your password"
                  type={this.getInputType(this.state.enableNewPasswordField)}
                  name="password"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={this.handleShowNewPassword}
                        data-test-id= "handleShowNewPassword"
                        edge="end"
                      >
                        {!this.state.enableNewPasswordField ? (
                          <img src={this.state.passwordVisible}  />
                        ) : (
                        <img src={this.state.passwordInVisible} />
                        )}
                      </IconButton>
                    </InputAdornment>
                    ),
                  }}
                  />
                </Box>
                <Box style={{ width: "520px",height: "82px", marginTop: "16px",}}>
                  <Typography 
                  style={{
                    lineHeight: "22px", letterSpacing: "-0.02em",
                    textAlign: "left", color: "#64748B",fontFamily: "Spartan",
                    fontSize: "14px", fontWeight: 700,
                  }}
                  >
                    Confirm password
                  </Typography>
                  <CustomTextfield 
                  onChange={this.handleConfirmPassword}
                  value={this.state.confirmPassword}
                  data-test-id="handleConfirmPassword"
                  error={!!this.state.confirmPasswordError}
                  placeholder="Confirm your password"
                  type={this.getInputType(this.state.enableConfirmPasswordField)}
                  name="confirm password"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                        onClick={this.handleShowConfirmPassword}
                        data-test-id="handleShowConfirmPassword"
                        edge="end"
                        >
                          {!this.state.enableConfirmPasswordField ? (
                            <img src={this.state.passwordVisible}  />
                          ) : (
                          <img src={this.state.passwordInVisible} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  />
                  <Typography 
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: "#DC2626",
                    }}>
                    {this.state.confirmPasswordError}
                  </Typography>
                </Box>  
              </Box>
              {this.state.newPassword && (
                <Box 
              style={{ width: "520px", marginTop: "4px" }}>
                <Box style={{ display: "flex", width: "186px", height: "18px"}}>
                  <Typography 
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "12px",
                    fontWeight: 700,
                    lineHeight: "18px",
                    textAlign: "left",
                    color: "#64748B",
                    letterSpacing: "-0.02em",
                  }}>
                    Your password must contain
                  </Typography>
                </Box>
                <Box style={{ width: "520px", marginTop: "4px" }}>
                  <Box style={{ display: "flex", alignItems: "center"}}>
                    {isUppercaseValid ? <CheckCircleOutlineIcon style={{color: '#059669', marginRight: '8px'}} /> : <CancelOutlinedIcon style={{ color: 'Red', marginRight: '8px' }} />}
                    <Typography 
                    style={{
                      fontFamily: "Spartan",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      letterSpacing: "-0.02em",
                      textAlign: "left",
                      color: "#475569",
                    }}
                    >
                      1 upper case letter
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", alignItems: "center", marginTop: "4px"}}>
                    {isLengthValid ? <CheckCircleOutlineIcon style={{color: '#059669', marginRight: '8px' }} /> : <CancelOutlinedIcon style={{color: 'Red', marginRight: '8px' }} />}
                    <Typography 
                    style={{
                      letterSpacing: "-0.02em",
                      textAlign: "left",
                      color: "#475569",
                      fontFamily: "Spartan",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                    }}
                    >
                      Between 8 and 20 characters
                    </Typography>
                  </Box> 
                  <Box style={{ display: "flex", alignItems: "center", marginTop: "4px",}}>
                    {isNumberValid ? <CheckCircleOutlineIcon style={{color: '#059669', marginRight: '8px', }} /> : <CancelOutlinedIcon style={{color: 'Red', marginRight: '8px' }} />}
                    <Typography 
                    style={{
                      lineHeight: "18px",
                      letterSpacing: "-0.02em",
                      fontFamily: "Spartan",
                      fontSize: "12px",
                      fontWeight: 500,
                      textAlign: "left",
                      color: "#475569",
                    }}
                    >
                      1 or more numbers
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", alignItems: "center", marginTop: "4px"}}>
                    {isSpecialCharValid ? <CheckCircleOutlineIcon style={{ color: '#059669', marginRight: '8px' }} /> : <CancelOutlinedIcon style={{color: 'Red', marginRight: '8px' }} />}
                    <Typography 
                    style={{
                      textAlign: "left",
                      color: "#475569",
                      fontFamily: "Spartan",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      letterSpacing: "-0.02em",
                    }}
                    >
                      1 or more special characters
                    </Typography>
                  </Box>
                </Box>
              </Box>
              )}
              <Box 
              style={{
                display:"flex", 
                flexDirection: "column", 
                width: "520px", 
              }}>
                <Box style={{display:"flex", justifyContent:"center", marginTop: "30px",}}>
                <Checkbox
                    checked={this.state.checked}
                    onChange={(e:any)=>this.handleCheckboxChange(e)}
                    data-test-id="handleCheckboxChange"
                    style={{
                      color: "#003249",
                      width: "24px",
                      height: "24px",
                      marginRight: "6px",
                    }}
                  />
                  <Typography 
                  style={{
                    fontFamily:"Spartan ",
                    fontSize:"12px",
                    lineHeight:"20px",
                    letterSpacing:"-2%",
                    color: "#475569",
                  }}
                  >
                    By creating an account you accept Haul-Inn's 
                    <span 
                    onClick={this.navigateToTermsConditions}
                    data-test-id="navigateToTermsConditions"
                    style={{
                      cursor: "pointer",
                      fontFamily: "Spartan",
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "22px",
                      letterSpacing: "-0.02em",
                      textDecoration:"underline",
                      color: "#475569",
                      marginLeft: "6px",
                      }}>
                        terms and conditions
                      </span>
                  </Typography>
                </Box>
                <Button disabled={this.state.firstName && this.state.lastName && this.state.email && this.state.phone && this.state.newPassword && this.state.confirmPassword && this.state.checked ? false: true}
                    style={{
                      background: this.state.firstName && this.state.lastName && this.state.email && this.state.phone && this.state.newPassword && this.state.confirmPassword && this.state.checked ? "#003249":'#94A3B8',
                      width:"100%",  
                      padding:"16px",
                      color: this.getColor(this.state),
                      borderRadius: "8px",
                      fontFamily: "Spartan",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "24px",
                      letterSpacing: "-0.03em",
                      textTransform:'none',
                      marginTop: "16px",
                      marginBottom: "74px",
                    }}
                    onClick={this.createApi}
                    data-test-id = "createApi">
                      Create an account
                </Button>
              </Box>
            </Box>
          </RightGrid>
        </Grid>
      </MainWrapper>
      <CustomDialog style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} 
        open={this.state.linkSent} 
        onClose={this.handleClose}
        data-test-id="handleClose">
            <Box
            style={{
                color: "#FFFFFF",
                padding:'29px 39px'
                }}
            >
                <Box
                style={{
                    display:'flex',
                    justifyContent:'center'
                }}>
                    <img src={this.state.dialogImage}/>
                </Box>
                <Box 
                style={{
                    marginTop: "62px",
                    }}>
                    <Box>
                        <Typography 
                        style={{
                            fontFamily: "Spartan",
                            fontSize: "30px",
                            fontWeight: "700",
                            lineHeight: "40px",
                            letterSpacing: "-0.03em",
                            textAlign: "center",
                            color: "#003249",
                        }}>
                            Check your email
                        </Typography>
                    </Box>
                    <Box>
                      <Typography 
                      style={{
                          fontFamily: "Spartan",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "-0.02em",
                          textAlign: "center",
                          color: "#475569",
                          width: "496px",
                      }}>
                          A confirmation email has been sent to your registered email address
                      </Typography>
                    </Box>
                </Box>
                <Box>
                  <Button 
                  onClick={this.handleButtonClick}
                  data-test-id = "handleButtonClick"
                  style={{
                    gap: "8px",
                    marginTop: "72px",
                    width: "100%",
                    padding:"16px",
                    color: "#FFFFFF",
                    backgroundColor: "#003249",
                    borderRadius: "8px",
                    fontFamily: "Spartan",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "24px",
                    letterSpacing: "-0.03em",
                    textTransform:'none',
                    }}>
                      Resend on email
                  </Button>
                </Box>
            </Box>
        </CustomDialog>
      </>
    );
  }

// Customizable Area End
}