// Customizable Area Start

import React from "react";
import {Typography, Box, Grid, TextField,styled, Button, InputAdornment,IconButton, Dialog} from '@mui/material';
import ForgotPasswordController, {Props,} from "./ForgotPasswordController";

const MainBoxTextArea=styled(Box)({
    position: "absolute",
    background: "#FFFFFF33",
    bottom:"55px",
    borderRadius: "5px",
    backdropFilter: "blur(5px)",
    padding:"1rem",
    textAlign: "center",
    paddingLeft: "55px",
    paddingRight: "55px"
  })
const RightGrid=styled(Grid)
({
    position: "relative",
    justifyContent: "center",
    paddingTop: "74px",
    paddingLeft: "112px",
    paddingRight: "112px",
})

const CustomDialog=styled(Dialog)({
    "& .MuiDialog-paper":{
      borderRadius: "15px"
    }
  })
export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }
  
  render() {
    return (
        <>
        <Grid container>
            <Grid item xs={12} sm={6} sx={{ position:"relative", display:'flex',justifyContent:'center' }}>
                <Box 
                style ={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                }}>
                <img src = {this.state.backImage} 
                style={{ 
                    objectFit: "cover",width: "100%",   
                    height: "100%",position:'relative',
                }}/>
                </Box>
                <Box 
                sx={{
                    backgroundColor: "#FEFEFE26",
                    width: { xs: "40px", sm: "48px", md: "54px" },
                    height: { xs: "40px", sm: "48px", md: "54px" }, 
                    borderRadius: "14px", position: "absolute", boxShadow: "-5px 4px 26px 0px #0000001A",
                    top: { xs: "20px", sm: "30px", md: "50px" }, 
                    left: { xs: "20px", sm: "30px", md: "50px" },opacity: "0px", gap: "0px",
                }}>
                    <Box 
                    sx={{
                        display: "flex",alignItems: "center",
                        justifyContent: "center",
                        padding: { xs: "8px", sm: "10px", md: "14px" }, 
                    }}>
                        <img src={this.state.arrowImage}
                        onClick={this.navigateToLoginPage}
                        data-test-id="navigateToLoginPage"/>
                    </Box>
                </Box>
                <MainBoxTextArea>
                    <Box>
                        <Typography 
                        style={{
                            color: "#FFFFFF",
                            fontFamily: "Spartan",
                            fontSize: "30px",
                            fontWeight: 700,
                            lineHeight: "40px",
                            letterSpacing: "-0.03em",
                            paddingTop:"28px",
                            paddingBottom: "10px",
                        }}>
                        Secure the Best Retreat for Your Horse
                        </Typography>
                    </Box>  
                    <Box>
                        <Typography 
                        style={{
                            color: "#FFFFFF",
                            fontFamily: "Spartan",
                            fontSize: "20px",
                            fontWeight: 500,
                            lineHeight: "30px",
                            letterSpacing: "-0.02em",
                            textAlign: "center", 
                            paddingBottom: "32px",
                        }}>
                        Easily locate dependable, temporary <br/> stabling options for peace of mind.
                        </Typography>
                    </Box>         
                </MainBoxTextArea>
            </Grid>
            <RightGrid item xs={12} sm={6} >
                <Box>
                    <Box>
                        <Typography 
                        style={{
                            fontFamily: "Spartan",
                            fontSize: "30px",
                            fontWeight: "700",
                            lineHeight: "40px",
                            letterSpacing: "-0.03em",
                            textAlign: "left",
                            color: "#003249",
                            paddingTop: "81px",
                        }}
                        >
                            Forgot Your Password?
                        </Typography>
                    </Box>
                    <Box>
                        <Typography 
                        style={{
                            fontFamily: "Spartan",
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "24px",
                            letterSpacing: "-0.02em",
                            textAlign: "left",
                            color: "#475569",
                        }}
                        >
                        Enter your registered email id and we’ll send an email with instructions to reset your password
                        </Typography>
                    </Box>
                </Box>
            <Box 
            style={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "8px",
                paddingTop: "78px",
              }}
            >
                <Typography
                variant="h6"
                style={{
                  fontFamily: "Spartan",
                  fontSize: "14px",
                  lineHeight: "22px",
                  letterSpacing: "-0.02em",
                  textAlign: "left",
                  color: "#64748B",
                }}
              >
                Email address
                </Typography>
                <TextField 
                onChange={this.handleEmailChange}
                value={this.state.email}
                data-test-id="handleEmailChange"
                error={!!this.state.emailError}
                // helperText={this.state.emailError}
                style={{
                    width: "100%",
                    height: "56px",
                    borderRadius: "8px",
                    border: "1px", 
                }}
                    placeholder="mail@example.com"
                    type="email"
                    name="email"
                    variant="outlined"
                    fullWidth
                />
            </Box>
            <Typography
            style={{
                fontFamily: "Spartan",
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "-0.02em",
                textAlign: "left",
                color: "#DC2626",
                }}>
                {this.state.emailError}
            </Typography>
            <Box 
            style={{
                marginTop: "170px",
                borderRadius: "8px",
                opacity: "0px",
            }}>
                <Button 
                onClick={this.handleButtonClick}
                data-test-id="handleButtonClick"
                style={{
                    width: "100%",
                    padding:"16px",
                    color: "#fff",
                    backgroundColor: "#003249",
                    borderRadius: "8px",
                    fontFamily: "Spartan",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "-0.03em",
                    marginBottom:"10px",
                    textTransform:'none',
                }}
                >
                    Reset my Password
                </Button>
            </Box>
          </RightGrid>
        </Grid>
        <CustomDialog style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} 
        open={this.state.linkSent} 
        onClose={this.handleClose}
        data-test-id="handleClose">
            <Box
            style={{ color: "#FFFFFF",padding:'50px 39px'}}>
                <Box
                style={{
                    display:'flex',
                    justifyContent:'center'
                }}>
                    <img src={this.state.dialogImage}/>
                </Box>
                <Box 
                style={{marginTop: "62px",}}>
                    <Box>
                        <Typography 
                        style={{
                            fontFamily: "Spartan",
                            fontSize: "30px",
                            fontWeight: "700",
                            letterSpacing: "-0.03em",
                            textAlign: "center",
                            color: "#003249",
                            lineHeight: "40px",
                        }}>
                            Check your email
                        </Typography>
                    </Box>
                    <Box>
                        <Typography 
                        style={{
                            fontFamily: "Spartan",letterSpacing: "-0.02em",fontSize: "16px",
                            fontWeight: "400",
                            lineHeight: "24px",
                            textAlign: "center",color: "#475569",
                            width: "496px",
                        }}>
                            We have sent an email with instructions to reset your password
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Button onClick={this.handleButtonClick}
                    style={{
                        gap: "8px",
                        fontFamily: "Spartan",
                        fontSize: "16px",fontWeight: 700,
                        lineHeight: "24px",letterSpacing: "-0.03em",
                        textTransform:'none',marginTop: "72px",
                        width: "100%",
                        padding:"16px",
                        color: "#FFFFFF",backgroundColor: "#003249",
                        borderRadius: "8px",
                        }}>
                            Resend on email
                    </Button>
                </Box>
            </Box>
        </CustomDialog>
        </>
    )
  }

}
// Customizable Area End
